<template>
  <div>
    <b-row v-if="drivers.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="drivers"
            @row-clicked="clicked"
            :fields="fields"
            head-row-variant="primary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(first_name)="data">
              <span>
                {{
                  data.item.patronymic
                    ? data.item.last_name +
                      " " +
                      data.item.first_name +
                      " " +
                      data.item.patronymic
                    : data.item.last_name + " " + data.item.first_name
                }}
              </span>
            </template>
            <template #cell(gender)="data">
              <span v-if="data.item.gender == 1"> Мужской </span>
              <span v-else> Женский </span>
            </template>
            <template #cell(rating)="data">
              <div @click="getratingInformation(data)" style="color: blue">
                {{ data.item.rating }}
              </div>
            </template>
            <template #cell(driver_cars)="data">
              <div @click="getCar(data)">
                <router-link
                  :to="{ name: 'carInfo', query: { driver_id: data.item.id } }"
                >
                  <span
                    v-for="car in data.item.driver_cars"
                    :key="car.id"
                    class="text-primary"
                    select-variant="secondary"
                  >
                    {{
                      data.item.driver_cars.length > 0
                        ? car.car_brand +
                          " " +
                          car.model +
                          "(" +
                          car.car_number +
                          ")" +
                          ", "
                        : ""
                    }}
                  </span>
                </router-link>
              </div>
            </template>
            <template #cell(balance)="data">
              <router-link
                :to="{
                  name: 'balance',
                  query: { driver_id: data.item.balance.performer_id },
                }"
                v-if="data.item.balance != null"
              >
                <span style="color: blue">
                  {{ data.item.balance }}
                </span>
              </router-link>
            </template>
            <template #cell(report)="data">
              <div @click="getAssigment(data)">
                <router-link
                  :to="{ name: 'memos', query: { phone: data.item.id } }"
                >
                  <div style="color: blue">(***)</div>
                </router-link>
              </div>
            </template>
            <template #cell(last_order_complated_date)="data">
              <div @click="getPathData(data)">
                <span style="color: blue">
                  {{ data.item.last_order_complated_date }}
                </span>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.css";

export default {
  data() {
    return {
      drivers: [],
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "rating",
          label: "Рейтинг",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "login",
          label: "Логин",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "first_name",
          label: "Ф.И.О.",
          sortable: true,
          thStyle: { width: "250px" },
        },
        {
          key: "driver_cars",
          label: "Машины",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "balance",
          label: "Баланс",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "created_by.login",
          label: "Создал",
          sortable: true,
          thStyle: { width: "127px" },
        },
        {
          key: "place_district",
          label: "Текущий район",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          thStyle: { width: "70px" },
        },
        {
          key: "date_of_birth",
          label: "Дата рождения",
          sortable: true,
          thStyle: { width: "130px" },
        },
        {
          key: "phone",
          label: "Телефон",
          sortable: true,
          thStyle: { width: "116px" },
        },
        {
          key: "contact_number",
          label: "Доп. номер тел.",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "serials_number",
          label: "Номер вод.удостоверения",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "expirated_driver_license",
          label: "Срок истечения вод. удостоверение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "gender",
          label: "Пол",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "serial_number_passport",
          label: "Номер паспорта",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "expirated_passport",
          label: "Срок истечения паспорта",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "passport_office",
          label: "Орган, выдающий документ",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "address",
          label: "Адрес",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "report",
          label: "СЗ",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "district",
          label: "Район",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Дата регистрации",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "last_order_complated_date",
          label: "Дата посл. заказа по подключению",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
      login: this.$route.query.filter,
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.resizeable(), this.getLogin();
  },
  methods: {
    getAssigment(data) {
      this.$router.push({ name: "memos", query: { filter: data.item.id } });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Служебные записки " + data.item.id,
        `/taxi/memos/index`,
        data.item.id,
      ]);
    },
    getCar(data) {
      this.$router.push({ name: "carInfo", query: { filter: data.item.id } });
      setTimeout(() => {
        this.$store.commit("draggableTab/ADD_TAB", [
          "Автомобиль " + data.item.id,
          `/driver/carInfo`,
          data.item.id,
        ]);
      }, 200);
    },
    getPathData(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "История " + data.item.id,
        `/taxi/driver/drivers-history-orders`,
        data.item.id,
      ]);
      this.$router.push({
        name: "drivers/drivers-history-orders",
        query: { driver_id: data.item.id },
      });
    },
    getratingInformation(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "Рейтинг " + data.item.id,
        `/taxi/rating/index`,
        data.item.id,
      ]);
      this.$router.push({ name: "rating", query: { filter: data.item.id } });
    },
    resizeable() {
      var thElm;
      var startOffset;
      Array.prototype.forEach.call(
        document.querySelectorAll("table th"),
        function (th) {
          th.style.position = "relative";
          var grip = document.createElement("div");
          grip.innerHTML = "&nbsp;";
          grip.style.top = 0;
          grip.style.right = 0;
          grip.style.bottom = 0;
          grip.style.width = "5px";
          grip.style.position = "absolute";
          grip.style.cursor = "col-resize";
          grip.addEventListener("mousedown", function (e) {
            thElm = th;
            startOffset = th.offsetWidth - e.pageX;
          });
          th.appendChild(grip);
        }
      );
      document.addEventListener("mousemove", function (e) {
        if (thElm) {
          thElm.style.width = startOffset + e.pageX + "px";
        }
      });
      document.addEventListener("mouseup", function () {
        thElm = undefined;
      });
    },
    clicked(item, index, event) {
      let allTd = document.querySelectorAll("td");
      allTd.forEach((el) => {
        el.style.backgroundColor = "";
        el.style.color = "";
      });
      let td = event.target;
      if (event.target.tagName.toLowerCase() === "td") {
        td.style.backgroundColor = "#2979ff";
        td.style.color = "#fff";
      } else if (event.target.tagName.toLowerCase() === "span") {
        let span = event.target;
        span.parentNode.style.backgroundColor = "#2979ff";
        span.parentNode.style.color = "#fff";
      }
    },
    getLogin() {
      this.$http.get(`drivers?filter_login=${this.login}`).then((res) => {
        this.drivers = res.data;
        this.$store.commit("pageData/setdataCount", this.drivers.length);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
